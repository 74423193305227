/* eslint-disable unicorn/prefer-module */
const React = require('react');
const { GoogleReCaptchaProvider } = require('react-google-recaptcha-v3');

// https://v4.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#wrapRootElement
exports.wrapRootElement = ({ element }) => {
    return (
        <GoogleReCaptchaProvider
            reCaptchaKey={process.env.GATSBY_RECAPTCHA_V3_PUBLIC_KEY}
            scriptProps={{
                defer: true,
            }}
        >
            {element}
        </GoogleReCaptchaProvider>
    );
};

exports.onRouteUpdate = () => {
    if (window.CLUTCHCO !== undefined) {
        window.CLUTCHCO.Init();
    }
};
